import { makeStyles } from "@material-ui/core/styles"

export default makeStyles(theme => {

    // dot group transition properties
    const duration = theme.transitions.duration.shortest + 'ms';
    const delay = (1000 - theme.transitions.duration.shortest) + 'ms';
    const tbase = `height ${duration}, margin ${duration}`;
    const tdelay = `height ${duration} ${delay}, margin ${duration} ${delay}`;

    return {
        root: {
            width: '100%',
            height: 'auto',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'relative',
            margin: 'auto',
            maxWidth: `calc(150vh - ${theme.components.navbar.height * 2 * 1.5}px)`,
        },
        responsive: {
            width: '80%',
            [theme.breakpoints.only('sm')]: {
                width: '50%',
            },
            [theme.breakpoints.up('md')]: {
                width: '100%',
            }
        },
        cover: {
            objectFit: 'cover',
        },
        animation: {
            transitionDuration: '.8s',
        },
        lazyPlaceholder: (state) => ({
            width: '100%',
            height: '100%',
            position: state.imagesReady ? 'absolute' : 'relative',
            top: 0,
            left: 0,
        }),
        dotGroup: {
            position: 'absolute',
            top: '100%',
            left: 0,
            marginTop: 1,
            width: '100%',
            display: 'flex',
            height: 4,
            marginBottom: 8,
            transition: tdelay,
            '&:hover, &:focus, &:active': {
                height: 12,
                marginBottom: 0,
                transition: tbase,
            }
        },
        dot: {
            height: '100%',
            flex: '1 1 auto',
            backgroundColor: theme.palette.grey[200],
            border: 'none',
            outline: 'none',
            transition: theme.transitions.duration.shortest + 'ms',
            '&:not(:first-of-type)': {
                marginLeft: 1,
            },
            '&:hover, &:focus, &:active': {
                backgroundColor: theme.palette.grey[300],
            },
            '&[class*="--selected"]': {
                backgroundColor: theme.palette.primary.light,
            },
        },
    }
})
