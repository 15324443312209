import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Fade from '@material-ui/core/Fade';
import { CarouselProvider, Slider, Slide, Image, DotGroup, Dot } from 'pure-react-carousel';
import useStyles from './carousel.css.js';
// styles
import "pure-react-carousel/dist/react-carousel.es.css";
import './style.css';
import { number } from 'prop-types';

const fadeTime = 1000;
const transitionTime = 500;

const Carousel = (props) => {

    const [state, setState] = React.useState({
      imagesReady: false,
      transitioning: false,
    });

    const classes = useStyles(state);

    const renderDots = () => props.images.map((node, i) => (
        <Dot key={i} slide={i} className={classes.dot} />
    ))

    const renderDotGroup = () => (
      <DotGroup
        className={classes.dotGroup}
        renderDots={renderDots}
        disableActiveDots={true}
        showAsSelectedForCurrentSlideOnly={true}
      />
    );

    // begins soft transition from placeholder image (first image) to carousel
    const beginTransition = () => {
      setState({ imagesReady: true, transitioning: true });
    }

    // init image lazy load with placeholder being the first image of the set
    React.useEffect(() => {

      // prevent finishing carousel load until after fadeTime completes
      const loadStart = Date.now();
      const earliestStart = loadStart + fadeTime;

      if (props.images.length > 1) {
        Promise.all(props.images.slice(1).map(node => {
          return new Promise(resolve => {
            const img = document.createElement('img');
            img.onload = () => {
              resolve(img.src);
            }
            img.src = node.publicURL;
          });
        })).then(sources => {
          const loadFinish = Date.now();
          const remainingTime = earliestStart - loadFinish;

          if (remainingTime > 0) {
            // if fadeTime has not completed, until the remaining difference
            setTimeout(beginTransition, remainingTime);
          } else {
            // render carousel immediately if load finishes after fadeTime
            beginTransition();
          }
        });
      }

      // if less than 2 images, don't render carousel

    }, []);

    React.useEffect(() => {
      // only trigger when beginning transition
      if (state.transitioning) {
        setTimeout(() => {
          setState(prevState => ({
            ...prevState,
            transitioning: false
          }));
        }, transitionTime);
      }
    }, [state.transitioning]);

    // When transitioning begins, the carousel will be instantly rendered beneath the placeholder.
    // Then, the placeholder will fade out using the Mui Fade component
    const renderCarousel = state.imagesReady;
    const renderPlaceholder = !state.imagesReady || state.transitioning;

    return (
      <div
        id="carousel-root"
        className={clsx({
          [classes.root]: true,
          [classes.responsive]: props.responsive,
        })}
      >
        {renderCarousel && (
          <CarouselProvider
            naturalSlideWidth={props.width}
            naturalSlideHeight={props.height}
            totalSlides={props.images.length}
            interval={props.interval}
            isPlaying={true}
            currentSlide={0}
            lockOnWindowScroll={true}
            style={{ width: "100%", height: "auto" }}
          >
            <Slider classNameAnimation={classes.animation}>
              {props.images.map((node, i) => (
                <Slide key={i} index={i}>
                  <Image
                    src={node.publicURL}
                    className={clsx({
                      [classes.cover]: props.cover,
                    })}
                  />
                </Slide>
              ))}
            </Slider>
          </CarouselProvider>
        )}
        {renderPlaceholder && (
          <Fade
            in={!state.imagesReady}
            timeout={state.imagesReady ? transitionTime : fadeTime}
          >
            <img
              src={props.images[0].publicURL}
              className={clsx({
                [classes.lazyPlaceholder]: true,
                [classes.cover]: props.cover,
              })}
            />
          </Fade>
        )}
      </div>
    )
}

Carousel.propTypes = {
    images: PropTypes.array,
    cover: PropTypes.bool,
    interval: PropTypes.number,
    width: number,
    height: number,
    responsive: PropTypes.bool,
}

Carousel.defaultProps = {
    images: [],
    cover: false,
    interval: 5000,
    width: 300,
    height: 200,
    responsive: false,
}

export default Carousel;